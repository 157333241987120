.product-upgrade-card {
  cursor: pointer;
  transition: transform 0.5s;

  .puc-company {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 3.6px;
    font-family: 'Montserrat';
    text-transform: uppercase;
    color: map-get($map: $theme-colors, $key: 'dark');
  }

  .puc-product-name {
    font-family: 'Montserrat';
    font-size: 21px;
    font-weight: 500;
    letter-spacing: 0.84px;
    color: map-get($map: $theme-colors, $key: 'black');
  }

}

.product-upgrade-selected {
  border:3px solid map-get($map: $theme-colors, $key: 'orange');
  // transform: translateY(-30px);
}

.splide__track {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.pretty-price-container {
  font-family: 'Open Sans',sans-serif;
  font-size: 50px;
  display: flex;
  justify-content: center;
  font-weight: 300;

  &.pp-smaller {
    transform: scale(0.8);
  }

  .price-item {
    display: flex;
    line-height: 0.8;
    position: relative;

    &.legacy-price {
      transform: scale(0.6);
      width: 42%;
      transform-origin: top left;
      
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 120px;
        border-bottom: 4px solid #EC1010;
        transform-origin: right top;
        transform: rotate(340deg);

      }
    }

    > div {
      padding:0 0.1rem 0 0.1rem;
    }

    .currency {
      font-size: 1.2rem;
    }

    .dollars {
      font-size: inherit;
    }

    .cents-period-container {
      display: flex;
      flex-direction: column;

      .cents {
        font-size: 1.2rem;
      }

      .period {
        font-size: 0.9rem;
        font-style: italic;
        white-space: nowrap;
      }
    }
  }
}