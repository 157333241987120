$theme-colors: (
  "black": #000,
  "blue": #1c6695,
  "red"           : #bb2d3b,
  "orange"          : #F3AE4C,
  "dark-grey"       : #6e6e6e,
  "dark"            : #4D5961,
  // specific elements
  "divider-blue"    : #246B9C,
  "white"           : #ffffff,
  "success"         : #198754,
  "info"            : #0dcaf0,
  "warning"         : #ffc107,
  "danger"          : #dc3545,
  "light-grey"      : #828282,
  "deep-grey"       : #707070,
  "background-grey" : rgb(248,248,248),
  "footer"          : rgb(74,87,96),
  "blue-start"      : rgb(49,101,145),
  "blue-end"        : rgb(94,175,240),
  "light-link"      : #40444B,
  "navy"            : #0D3151,
  "border-orange"   : #C23338,
);

$fa-font-path: "/assets/fonts/fa6";