
.poweredby {
  border-left: 1px solid gray;
  margin: 0 0 0 20px;
  padding: 0 0 0 20px;

  span {
    font-size: 12px;
    color: gray;
    font-weight: 300;
  }

  img {
    height: 35px;
  }
}