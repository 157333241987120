@import '_variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//Font Awesome
@import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";

// Datepicker
// @import '../node_modules/vanillajs-datepicker/sass/datepicker-bs5.scss';
@import '../node_modules/vanillajs-datepicker/dist/css/datepicker-bs5.min.css';

html,
body {
  font-family: Poppins, sans-serif;
}

@import 'products';
@import 'header';
// @import 'auth';
// @import 'scan';